<template>
  <drawer-form
    ref="drawerForm"
    title="分润比例设置"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">


    <a-card bordered :title="deptTitle(dept)" v-for="dept in deptList" class="dept-item">


      <div class="user-row" v-for="user in dept.userList">
        <div class="user-name">{{user.remark}}</div>
        <div class="user-phone">{{user.phone}}</div>
        <a-input v-model="user.rate" addon-before="分润比例" addon-after="%"></a-input>
      </div>

    </a-card>

    <div style="height:80px"></div>

  </drawer-form>

</template>
<script>

  export default {
    data() {
      return {
        deptList:[],
        model: {},
        rules: {},
        loading: false,

      }
    },

    methods: {
      deptTitle(dept){
        let totalRate = 0
        dept.userList.forEach(user=> totalRate+=(user.rate||0))
        return dept.name+",分润比例"+totalRate+"%"
      },
      show(model={}) {
        this.model = JSON.parse(JSON.stringify(model))
        this.loading=false
        this.$refs.drawerForm.show()

        this.$get("web/profit/user/all",{projectId:this.model.projectId}).then(suc=>{
          this.deptList = suc.data
        })
      },

      hide() {
        this.$refs.drawerForm.hide()
      },

      handleSubmit() {
        // if (this.model.id > 0) {
        //   this.edit()
        // } else {
        //   this.add()
        // }

        this.loading = true
        this.$postJson('web/profit/user/all?projectId='+this.model.projectId, this.deptList).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })

      },
      add() {
        this.loading = true
        this.$post('web/profit/user', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

      edit() {
        this.loading = true
        this.$put('web/profit/user', this.model).then((r) => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(() => {
          this.loading = false
        })
      },

    }
  }
</script>
<style lang="less" scoped>
  .dept-item{
    margin-bottom: 10px;
  }
  .user-row{
    display: flex;
    align-items: center;
    margin: 10px 0;

    .user-name{
      width: 150px;
    }

    .user-phone{
      width: 300px;
    }
  }
</style>
