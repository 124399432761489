<template>
  <page-content>

    <a-card title="项目信息">
      <a-descriptions title="" bordered size="small" :column="{sm:2}">
        <a-descriptions-item label="名称">{{info.name}}</a-descriptions-item>
        <a-descriptions-item label="创建时间">{{info.createdAt}}</a-descriptions-item>
        <a-descriptions-item label="分润次数">{{info.distributeCount}}</a-descriptions-item>
        <a-descriptions-item label="累计分润金额">{{info.distributeMoney/100}}元</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-card class="device-info" title="项目成员" style="margin-top: 20px">

      <common-table
        ref="table"
        path="web/profit/user"
        :queryParams="queryParams"
        :columns="columns">

        <template slot="search">
          <a-button ghost type="primary" @click="add">编辑</a-button>
        </template>


        <template slot="operation" slot-scope="{record}">
<!--          <action-view @click="view(record)"></action-view>-->
          <action-edit  @click="edit(record)"></action-edit>
          <action-delete  @click="remove(record)"></action-delete>
        </template>

      </common-table>

    </a-card>

    <project-user-edit ref="editDrawer" @success="getList"></project-user-edit>

    <project-dept-user-edit ref="editDeptUser" @success="getList"></project-dept-user-edit>

  </page-content>
</template>
<script>
  import ProjectUserEdit from './ProjectUserEdit'
  import ProjectDeptUserEdit from "./ProjectDeptUserEdit";
  export default {
    components: {
      ProjectUserEdit,ProjectDeptUserEdit
    },
    data () {
      return {
        deptList:[],
        info:{},
        queryParams:{
          projectId:this.$route.params.id
        },
      }
    },
    created() {
      let projectId = this.$route.params.id
      //获取项目详情
      this.$get("web/profit/project/"+projectId).then(suc=>{
        this.info = suc.data
      })

      //获取分润比例设置
      this.$get("web/profit/user/all",{projectId}).then(suc=>{
        this.deptList = suc.data
      })

    },

    computed: {
      columns () {
        return [
          {
            title: '部门',
            dataIndex: 'deptName',
          },
          {
            title: '姓名',
            dataIndex: 'name',
          },
          {
            title: '手机号',
            dataIndex: 'phone'
          },
          {
            title: '分润比例',
            dataIndex: 'rate',
            customRender: (text, row, index) => {
              return text + "%"
            },
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' }
          }

        ]
      }
    },
    methods: {
      add () {
        this.$refs.editDeptUser.show({projectId:this.queryParams.projectId})
      },
      view(record){
        this.$router.push("/profit/project/"+record.id)
      },
      edit (record) {
        this.$refs.editDrawer.show(record)
      },
      remove (record) {
        var that = this
        this.$confirm({
          title: '是否删除记录?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('web/profit/user' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      getList () {
        this.$refs.table.getData()
      }

    }
  }
</script>
<style lang="less" scoped>

  .news-logo {
    width: 60px;
    height: 60px;
  }
</style>
